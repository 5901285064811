exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anews-index-js": () => import("./../../../src/pages/anews/index.js" /* webpackChunkName: "component---src-pages-anews-index-js" */),
  "component---src-pages-anews-wp-a-news-slug-js": () => import("./../../../src/pages/anews/{WpANews.slug}.js" /* webpackChunkName: "component---src-pages-anews-wp-a-news-slug-js" */),
  "component---src-pages-cjelozivotno-ucenje-index-js": () => import("./../../../src/pages/cjelozivotno-ucenje/index.js" /* webpackChunkName: "component---src-pages-cjelozivotno-ucenje-index-js" */),
  "component---src-pages-cjelozivotno-ucenje-wp-tecaj-slug-js": () => import("./../../../src/pages/cjelozivotno-ucenje/{WpTecaj.slug}.js" /* webpackChunkName: "component---src-pages-cjelozivotno-ucenje-wp-tecaj-slug-js" */),
  "component---src-pages-dokumenti-index-js": () => import("./../../../src/pages/dokumenti/index.js" /* webpackChunkName: "component---src-pages-dokumenti-index-js" */),
  "component---src-pages-erasmus-arhiva-js": () => import("./../../../src/pages/erasmus/arhiva.js" /* webpackChunkName: "component---src-pages-erasmus-arhiva-js" */),
  "component---src-pages-erasmus-index-js": () => import("./../../../src/pages/erasmus/index.js" /* webpackChunkName: "component---src-pages-erasmus-index-js" */),
  "component---src-pages-erasmus-natjecaji-js": () => import("./../../../src/pages/erasmus/natjecaji.js" /* webpackChunkName: "component---src-pages-erasmus-natjecaji-js" */),
  "component---src-pages-erasmus-wp-erasmus-iskustvo-slug-js": () => import("./../../../src/pages/erasmus/{WpErasmusIskustvo.slug}.js" /* webpackChunkName: "component---src-pages-erasmus-wp-erasmus-iskustvo-slug-js" */),
  "component---src-pages-erasmus-wp-erasmus-natjecaj-database-id-js": () => import("./../../../src/pages/erasmus/{WpErasmusNatjecaj.databaseId}.js" /* webpackChunkName: "component---src-pages-erasmus-wp-erasmus-natjecaj-database-id-js" */),
  "component---src-pages-erasmus-wp-erasmus-novost-slug-js": () => import("./../../../src/pages/erasmus/{WpErasmusNovost.slug}.js" /* webpackChunkName: "component---src-pages-erasmus-wp-erasmus-novost-slug-js" */),
  "component---src-pages-galerija-index-js": () => import("./../../../src/pages/galerija/index.js" /* webpackChunkName: "component---src-pages-galerija-index-js" */),
  "component---src-pages-galerija-wp-galerija-slug-js": () => import("./../../../src/pages/galerija/{WpGalerija.slug}.js" /* webpackChunkName: "component---src-pages-galerija-wp-galerija-slug-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kontakt-old-js": () => import("./../../../src/pages/kontakt.old.js" /* webpackChunkName: "component---src-pages-kontakt-old-js" */),
  "component---src-pages-o-nama-dekan-js": () => import("./../../../src/pages/o-nama/dekan.js" /* webpackChunkName: "component---src-pages-o-nama-dekan-js" */),
  "component---src-pages-o-nama-kvaliteta-js": () => import("./../../../src/pages/o-nama/kvaliteta.js" /* webpackChunkName: "component---src-pages-o-nama-kvaliteta-js" */),
  "component---src-pages-o-nama-misija-i-vizija-js": () => import("./../../../src/pages/o-nama/misija-i-vizija.js" /* webpackChunkName: "component---src-pages-o-nama-misija-i-vizija-js" */),
  "component---src-pages-o-nama-nakladnistvo-index-js": () => import("./../../../src/pages/o-nama/nakladnistvo/index.js" /* webpackChunkName: "component---src-pages-o-nama-nakladnistvo-index-js" */),
  "component---src-pages-o-nama-organizacija-js": () => import("./../../../src/pages/o-nama/organizacija.js" /* webpackChunkName: "component---src-pages-o-nama-organizacija-js" */),
  "component---src-pages-o-nama-predmeti-js": () => import("./../../../src/pages/o-nama/predmeti.js" /* webpackChunkName: "component---src-pages-o-nama-predmeti-js" */),
  "component---src-pages-o-nama-profesori-index-js": () => import("./../../../src/pages/o-nama/profesori/index.js" /* webpackChunkName: "component---src-pages-o-nama-profesori-index-js" */),
  "component---src-pages-o-nama-profesori-wp-profesor-slug-js": () => import("./../../../src/pages/o-nama/profesori/{WpProfesor.slug}.js" /* webpackChunkName: "component---src-pages-o-nama-profesori-wp-profesor-slug-js" */),
  "component---src-pages-privatnost-js": () => import("./../../../src/pages/privatnost.js" /* webpackChunkName: "component---src-pages-privatnost-js" */),
  "component---src-pages-skupovi-index-js": () => import("./../../../src/pages/skupovi/index.js" /* webpackChunkName: "component---src-pages-skupovi-index-js" */),
  "component---src-pages-skupovi-wp-skup-slug-js": () => import("./../../../src/pages/skupovi/{WpSkup.slug}.js" /* webpackChunkName: "component---src-pages-skupovi-wp-skup-slug-js" */),
  "component---src-pages-studenti-aai-js": () => import("./../../../src/pages/studenti/aai.js" /* webpackChunkName: "component---src-pages-studenti-aai-js" */),
  "component---src-pages-studenti-akademski-kalendar-js": () => import("./../../../src/pages/studenti/akademski-kalendar.js" /* webpackChunkName: "component---src-pages-studenti-akademski-kalendar-js" */),
  "component---src-pages-studenti-knjiznica-js": () => import("./../../../src/pages/studenti/knjiznica.js" /* webpackChunkName: "component---src-pages-studenti-knjiznica-js" */),
  "component---src-pages-studenti-raspored-predavanja-js": () => import("./../../../src/pages/studenti/raspored-predavanja.js" /* webpackChunkName: "component---src-pages-studenti-raspored-predavanja-js" */),
  "component---src-pages-studenti-referada-js": () => import("./../../../src/pages/studenti/referada.js" /* webpackChunkName: "component---src-pages-studenti-referada-js" */),
  "component---src-pages-studenti-savjetovanje-js": () => import("./../../../src/pages/studenti/savjetovanje.js" /* webpackChunkName: "component---src-pages-studenti-savjetovanje-js" */),
  "component---src-pages-studenti-studentski-zbor-js": () => import("./../../../src/pages/studenti/studentski-zbor.js" /* webpackChunkName: "component---src-pages-studenti-studentski-zbor-js" */),
  "component---src-pages-studenti-zavrsni-radovi-index-js": () => import("./../../../src/pages/studenti/zavrsni-radovi/index.js" /* webpackChunkName: "component---src-pages-studenti-zavrsni-radovi-index-js" */),
  "component---src-pages-studenti-zavrsni-radovi-wp-zavrsni-rad-slug-js": () => import("./../../../src/pages/studenti/zavrsni-radovi/{WpZavrsniRad.slug}.js" /* webpackChunkName: "component---src-pages-studenti-zavrsni-radovi-wp-zavrsni-rad-slug-js" */),
  "component---src-pages-studiji-alumni-klub-js": () => import("./../../../src/pages/studiji/alumni-klub.js" /* webpackChunkName: "component---src-pages-studiji-alumni-klub-js" */),
  "component---src-pages-studiji-index-js": () => import("./../../../src/pages/studiji/index.js" /* webpackChunkName: "component---src-pages-studiji-index-js" */),
  "component---src-pages-studiji-nastavni-plan-js": () => import("./../../../src/pages/studiji/nastavni-plan.js" /* webpackChunkName: "component---src-pages-studiji-nastavni-plan-js" */),
  "component---src-pages-studiji-strucna-praksa-js": () => import("./../../../src/pages/studiji/strucna-praksa.js" /* webpackChunkName: "component---src-pages-studiji-strucna-praksa-js" */),
  "component---src-pages-studiji-syllabus-index-js": () => import("./../../../src/pages/studiji/syllabus/index.js" /* webpackChunkName: "component---src-pages-studiji-syllabus-index-js" */),
  "component---src-pages-studiji-upisi-js": () => import("./../../../src/pages/studiji/upisi.js" /* webpackChunkName: "component---src-pages-studiji-upisi-js" */),
  "component---src-pages-upzu-js": () => import("./../../../src/pages/upzu.js" /* webpackChunkName: "component---src-pages-upzu-js" */)
}

